import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ThumbQuestionT } from "../../../../content/static/onboardingQuestions";
import { AppText, FlexDiv } from "../../../ui";
import { useOnboardingContext } from "../OnboardingContext";
import theme from "../../../../util/theme";
import { mobileSize } from "../../../../util/variables";
import { web_acq_question_click } from "../../../../services/mixpanel/acquisition-events";
import ThumbUp from "../../../../content/img/icon/thumb-up.svg";
import ThumbDown from "../../../../content/img/icon/thumb-down.svg";
import { fadeIn, slideAndRotateLeft, slideAndRotateRight } from "../../../../styles/animations";
import { trackEvent } from "../../../../services/mixpanel/mixpanel";

interface ThumbQuestionProps {
  data: ThumbQuestionT;
  questionNumber: number;
  nextQuestion: () => void;
}

interface SelectProps {
  label: "Yes" | "No";
  icon: string;
  alt: string;
  selected: boolean;
  updateChoice: (value: string, exists: boolean) => void;
  image?: string;
}

const ThumbQuestion: React.FC<ThumbQuestionProps> = ({
  data: { id, title, asset, author },
  nextQuestion,
  questionNumber,
}) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [choice, setChoice] = useState("");
  const { updateOnboardingAnswer } = useOnboardingContext();

  useEffect(() => {
    if (choice) {
      setTriggerAnimation(true);
    }
  }, [choice]);

  const updateChoice = React.useCallback(
    (value: string) => {
      setChoice(value);
      web_acq_question_click(id);
    },
    [id],
  );

  const handleAnimationEnd = () => {
    console.log("transition end");
    if (!choice) {
      return;
    }

    trackEvent(
      choice === "Yes"
        ? "Onboarding_BookInterests_Interested"
        : "Onboarding_BookInterests_NotInterested",
      {
        "Book Name": title,
      },
    );
    nextQuestion();
    updateOnboardingAnswer(id, choice);
    setChoice("");
    setTriggerAnimation(false);
  };

  return (
    <FlexDiv
      direction="column"
      align="center"
      mobileJustify="space-between"
      style={{ textAlign: "center", flexGrow: 1 }}
    >
      <div>
        <AppText noMargin fontSize={24} mobileFontSize={20} fontWeight={500}>
          Is this interesting to you?
        </AppText>
        <Subtitle style={{ marginBottom: 20 }}>Title {questionNumber} of 4</Subtitle>
      </div>
      <ContentWrapper>
        <AnimatedDiv
          trigger={triggerAnimation}
          direction={choice === "No" ? "left" : "right"}
          onAnimationEnd={handleAnimationEnd}
        >
          <Img src={asset} width={314} height={355} />
          <AppText noMargin fontWeight={500} style={{ marginTop: 20 }}>
            {title}
          </AppText>
          {author && <Subtitle>{author}</Subtitle>}
        </AnimatedDiv>
      </ContentWrapper>
      <OptionsContainer>
        <Select
          label="No"
          icon={ThumbDown}
          alt="thumb down"
          updateChoice={updateChoice}
          selected={choice === "No"}
        />
        <Select
          label="Yes"
          icon={ThumbUp}
          alt="thumb up"
          updateChoice={updateChoice}
          selected={choice === "Yes"}
        />
      </OptionsContainer>
    </FlexDiv>
  );
};

const ContentWrapper = styled.div`
  min-height: 310px;
  height: 55dvh;
  max-height: 370px;

  @media ${mobileSize} {
    padding: 0 20px;
    max-height: none;
  }
`;

const Subtitle = styled(AppText as any).attrs({
  fontSize: 16,
  mobileFontSize: 14,
  noMargin: true,
})`
  max-width: 468px;
`;

const AnimatedDiv = styled.div<{ trigger: boolean; direction: "left" | "right" }>`
  opacity: ${props => (props.trigger ? 1 : 0)};
  animation: ${({ trigger, direction }) =>
      trigger ? (direction === "left" ? slideAndRotateLeft : slideAndRotateRight) : fadeIn}
    ${props => (props.trigger ? 0.75 : 0.5)}s ease-in-out forwards;
  animation-delay: ${props => (props.trigger ? 0 : 0.1)}s;

  @media ${mobileSize} {
    animation-duration: 0.5s;
  }
`;

const Img = styled.img`
  max-height: min(42dvh, 355px);
  margin: 0 auto;
  object-fit: cover;
  border-radius: 24px;

  @media ${mobileSize} {
    height: 280px;
    width: 280px;
  }
`;

const OptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 64px;
  margin-top: 80px;
  padding: 12px;
  max-width: 100vw;
  overflow: auto;
  @media ${mobileSize} {
    /* position: fixed;
    bottom: 24px; */
    margin-top: 24px;
    padding: 8px 32px 8px 32px;
    gap: 10px;
  }
`;

// ==========================================================================================

const Select: React.FC<SelectProps> = ({ label, icon, alt, updateChoice, selected }) => {
  return (
    <Container selected={selected} label={label} onClick={() => updateChoice(label, selected)}>
      <AppText
        noMargin
        fontWeight={selected ? 500 : 400}
        mobileFontSize={16}
        style={{ maxWidth: 200 }}
      >
        {label}
      </AppText>
      <img src={icon} alt={alt} />
    </Container>
  );
};

const Container = styled.div<{ selected: boolean; label: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  width: 220px;
  max-width: 100%;
  height: 86px;

  background-color: ${({ selected, label }) =>
    selected ? (label === "Yes" ? theme.SELECTED_COLOR : "#FBE2E2") : theme.WHITE_COLOR};
  outline: ${({ selected, label }) =>
    selected ? `1px solid ${label === "Yes" ? theme.SELECTED_BORDER_COLOR : theme.RED}` : "none"};
  border-radius: 8px;
  box-shadow: ${props =>
    props.selected ? "0px 1px 7px rgba(44, 39, 56, 0.14)" : "0px 2px 11px rgba(44, 39, 56, 0.14)"};
  cursor: pointer;
  @media ${mobileSize} {
    height: 64px;
    min-height: 64px;
  }
`;

export default ThumbQuestion;
