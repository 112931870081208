import { keyframes } from "styled-components";

export const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const fadeOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

export const slideIn = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
`;

export const slideOut = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;

export const slideAndRotateRight = keyframes`
  0% { transform: translateX(0) rotate(0deg); }
  100% { transform: translateX(100vw) rotate(45deg); }
`;

export const slideAndRotateLeft = keyframes`
  0% { transform: translateX(0) rotate(0deg); }
  100% { transform: translateX(-100vw) rotate(-45deg); }
`;
